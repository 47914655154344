import { IPlayer } from '../components/types'
import { getImageSrc } from './utils'
import { video_fields } from '../__generated__/video_fields'

export const createVideo = (data: video_fields): IPlayer => {
  if (!data) {
    return null
  }
  const video = data?.targetElement?.propertyValuesRelation.find(
    (value) => value.property?.code === 'VIDEO',
  )?.targetFile?.url

  if (!video) {
    return null
  }

  const title = data?.targetElement?.name
  const description = data?.targetElement?.detailText
  const poster = data?.targetElement?.detailPicture?.url

  return {
    video: getImageSrc(video),
    poster: getImageSrc(poster),
    title,
    description,
  }
}

import React, { FC, useEffect, useRef, useState } from 'react'
import css from './Player.module.scss'
import Plyr from 'plyr'
import cx from 'classnames'
import Image from 'next/image'
import PlayIcon from '../../../public/static/icons/play-white.svg'
import PlayIconMob from '../../../public/static/icons/play-white-mob.svg'
import MutedIcon from '../../../public/static/icons/muted.svg'
import UnmutedIcon from '../../../public/static/icons/unmuted.svg'
import { IPlayer } from '../../types'

const Player: FC<IPlayer> = ({ video, poster, title, description }) => {
  const [plyrInstance, setPlyrInstance] = useState<Plyr>(null)
  const [play, setPlay] = useState(false)
  const [muted, setMuted] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null)

  const plyrOptions: Plyr.Options = {
    fullscreen: { enabled: false },
    controls: ['progress'],
    muted,
  }

  useEffect(() => {
    if (videoRef) {
      setPlyrInstance(new Plyr(videoRef.current, plyrOptions))
    }
  }, [])

  useEffect(() => {
    if (plyrInstance) {
      plyrInstance.volume = 0.5
    }
  }, [plyrInstance])

  useEffect(() => {
    if (plyrInstance) {
      plyrInstance.muted = muted
    }
  }, [muted, plyrInstance])

  useEffect(() => {
    plyrInstance?.togglePlay(play)
  }, [play])

  return (
    <div className={css.wrapper}>
      <div className={cx(css.preview_wrapper, play && css.played)}>
        {!play && (
          <>
            <figure className={css.poster}>
              <Image
                src={poster}
                alt={title}
                width={1265}
                height={600}
                objectFit="cover"
                loading="lazy"
                sizes="(max-width: 992px) 100vw, (max-width: 1080px) 1000px, (max-width: 1200px) 1120px, (max-width: 1300px) 1116px, (max-width: 1400px) 1216px, 1380px"
              />
            </figure>
            <div className={css.controls}>
              <div className={css.text}>
                <h3 className={cx('apoc', css.title)}>{title}</h3>
                {description && <p>{description}</p>}
              </div>
              <PlayIconMob
                className={css.play_mobile}
                onClick={() => {
                  setPlay(true)
                }}
              />
              <PlayIcon
                className={css.play}
                onClick={() => {
                  setPlay(true)
                }}
              />
            </div>
          </>
        )}

        <div
          className={cx(css.video_wrapper)}
          onClick={(e) => {
            if (
              play &&
              (e.target as HTMLElement).classList.contains('plyr__poster')
            ) {
              setPlay(false)
            }
          }}
        >
          <button
            className={css.video_mute}
            onClick={(e) => {
              e.stopPropagation()
              setMuted((prev) => !prev)
            }}
            type="button"
          >
            {muted ? <MutedIcon /> : <UnmutedIcon />}
          </button>
          <video playsInline ref={videoRef} src={video} className={css.video} />
        </div>
      </div>
    </div>
  )
}

export default Player

import gql from 'graphql-tag'

export const VIDEO_FILEDS = gql`
  fragment video_fields on SettingPlus {
    id
    targetElement {
      id
      name
      code
      detailText
      detailPicture {
        id
        url
      }
      propertyValuesRelation {
        property {
          code
        }

        targetFile {
          url
        }
      }
    }
  }
`
